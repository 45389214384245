.accordion-button {
    font-family: 'Raleway' !important;
    background-color: #e1dfd7;
    font-size: 16px;
}

.checked{
  background: rgb(0 157 36 / 63%) !important;
  color: #333333;
  border:1px solid rgb(0 157 36 / 63%);
}

.accordion-button[aria-expanded="true"] {
    background-color: #02559c;
    color: #FFF;
}

.accordion-button:focus {
    box-shadow: none;
}
