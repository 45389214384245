.header {
    position:sticky;
    top:0;
    z-index: 10;
}

table th td{
    resize: both;
}

.bg-light.assigned , .assigned{
    background: rgb(0 157 36 / 63%) !important;
    color: #FFF !important;
}

.bg-light.prev-assinged, .prev-assinged{
    background: #D7A108 !important;
    color: #FFF !important;
}

.bg-light.assinged-defaults, .assinged-defaults{
    background: #6F61C0 !important;
    color: #FFF !important;
}