.list-group-item {
    position: relative;
    padding-left: 30px;
}

.list-group-item.completed {
    background-color: #d4edda; /* Light green background for completed items */
    color: #155724; /* Dark green text for completed items */
}

.list-group-item .tick {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2em;
    color: #155724; /* Dark green color for the tick */
}
.custom-tabs .nav-tabs {
    border-bottom: 2px solid #dee2e6 !important;
}

.custom-tabs .nav-link {
    color: #495057 !important;
    background-color: #f8f9fa !important;
    border: 1px solid #dee2e6 !important;
    border-radius: 0;
    margin-right: -1px;
    padding: 10px 15px;
    text-align: center;
}

.custom-tabs .nav-link.active {
    color: #fff !important;
    background: #02559c !important;
    border-color: #007bff #007bff #fff !important;
}

.custom-tabs .nav-link:hover {
    color: #0056b3 !important;
    background-color: #e9ecef !important;
    border-color: #dee2e6 !important;  
}
.tab-content {
    background-color: #fff;
    padding:12px;
}
.tab-pane table td {
    font-size: small;
}