body {
    font-family: 'Raleway';
    background: #eee;
}

label{
    font-size: 16px;
}

.login-bg{
    background-repeat: no-repeat;
    background-position: 0%;
    background-size: cover;
}

.header-logo{
    filter: brightness(100);
}

.login-btn{
    height: 45px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.form-element{
    max-width: 330px;
}
