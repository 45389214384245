body {
    font-family:'Roboto', Helvetica, Arial, Lucida, sans-serif ;
    /* background-color: #f7f7f7; */
    color: #022742!important;
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat','Roboto Condensed','Roboto', Helvetica, Arial, Lucida, sans-serif ;
    /* color: #022742!important; */
    /* font-weight: 300 !important; */
}
a {
    color: #022742;
    text-decoration: none;
}
.title {
    font-family: 'Roboto Condensed', sans-serif;
    letter-spacing: 0.4rem;
    font-size: 1.5rem;
}

.cursor-pointer {
    cursor: pointer;
}

.page-content {
    margin-left: 0rem;
    /* Match the sidebar width */
}

.selectors {
    width: 100%;
}
.gap-3 span {
    margin-right:.6em;
} 
.menu-category span {
    margin-right:.5em;
} 
.side-panel a:active {
    background: #003265;
}
.offcanvas-body span {
    width: 5%;
}
.menu-category { 
    padding:.5em;
    font-size: 1.1rem;
    line-height: 0.75rem;
    font-weight: 600;
    letter-spacing: .00937em;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-decoration: inherit;
    text-transform: inherit;
    color: rgb(7 69 128 / 95%);
    border-bottom: 1px solid rgb(7 69 128 / 40%);
}
.side-panel .btn {
    border-radius: 0em;
}
.side-panel .offcanvas-body{
    padding-left:0em;
    padding-right:0em;
}
/* Legend for SAIL form  */
/* Style for the legend */
.legend {
    margin-top: 20px;
    padding: 5px 10px ;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: left;
    width: fit-content;
}
.color-box {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin: 5px 10px 0px 0;
}
/* Style for color boxes */
.color-box-yellow {
    background-color: #e94e4e !important;
}
.color-box-green {
    background-color: #78b378 !important;
}

/* Style for color labels */
.color-label {
    vertical-align: text-bottom;
    margin: 5px 10px 0px 0;
}

.selected-values-display {
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 5px 0 5px;
    background-color: #02559c;
}
.selected-values-display h4{
    /* background-color: #02559c; */
    color: #fff;
}
.page-content {
    margin-left: 5rem;
    margin-right: 2.5rem;
}

@media (max-width: 576px) { /* For mobile devices */
    .hide-mobile {
      display: none;
    }
  }
/* Medium devices (tablets, 768px and up) */
@media (max-width: 576px) {
    .page-content {
        margin-left: .5rem;
        margin-right: .5rem;
        /* Match the sidebar width */
    }

    .home-page-content {
        margin-left: .5rem;
        margin-right: .5rem;
        /* Match the sidebar width */
    }

    .selectors {
        width: 75%;
    }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .page-content {
        margin-left: 6rem;
        margin-right: 1rem;
        /* Match the sidebar width */
    }

    .home-page-content {
        margin-left: 4rem;
        margin-right: 0rem;
        /* Match the sidebar width */
    }

    .selectors {
        width: 75%;
    }
}

.bg-sp-primary {
    background-color: #02559c;
}

.d-flex rounded p-2 label {
    color:#fff;
}
@media only screen and (min-width: 1025px) {
    button.btn-field {
        width: 90%;
    }

    .selectors {
        width: 50%;
    }
}

@media (max-width: 500px) {
    .confluence-embedded-file-wrapper img {
        width: -webkit-fill-available;
    }
}

/* width */
::-webkit-scrollbar {
    height: 8px;
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #555;
}

.leaf-icon{
    width: 25%;
    transform: scale(0.45);
}


.tooltip-alert {
    margin-top: -4px;
    border: 0px 1px 1px 1px solid #ccc !important;
    padding: 6px;
  }
  
  .tooltip-alert p {
    margin: 0;
    font-size: 0.9em;
  }
  
  .limit-reached {
    color: red;
    font-weight: bold;
  }
  .max-selections-message {
    padding: 5px 0 5px 15px;
    color: red;
    font-weight: bold;
    text-align: left;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    margin: 0;
  }
  .sail-selections-font {
    font-size: 0.9rem !important;
  }