#sidebarMenu {
    position: fixed;
    height: 100%;
    width: 4.5rem; /* Set your desired width */
    overflow-x: hidden;
}

.offcanvas-header .btn-close {
    color: #fff !important;
    background-color: #fff;
    font-size: x-small;
}
.ofc-btn{
    font-family: 'Montserrat','Roboto Condensed','Roboto', Helvetica, Arial, Lucida, sans-serif !important;
    font-size: 1.1em;
    border: 0px solid white !important;
}

.ofc-btn:hover{
    background-color: #2a96f5c7 !important;
    color: #FFF !important;
}

.active > .ofc-btn{
    color: #FFF;
    background-color: #02559c !important;
}


.side-btn{
    color: black;
}

.side-btn:hover{
    background-color: #2a96f5c7 !important;
    color: #000 !important;
}

.side-btn.active > .btn {
    color: #ffffff;
}

a.side-btn.active {
    
    background: #02559c;
}