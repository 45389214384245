.nav-link{
    color: #FFF;
}

.nav-link.active{
    color: yellow;
}

@keyframes slideDown {
        from {
        opacity: 0;
        transform: translateY(-20px);
        }
        to {
        opacity: 1;
        transform: translateY(0);
        }
  }
  
  .slide-down {
        animation: slideDown 0.3s ease-out;
  }
  .drag-handle {
    font-size: 14px;
    color: #fff;
    padding: 4px 0;
    text-align: center;
}

.drag-handle:hover {
    cursor: move;
}

.drag-handle .panel {
    box-shadow: none;
    border: none;
}

.drag-handle .panel:focus {
    outline: none;
}

.box-style {
    position: 'relative';
    background-color: '#FFF';
    border: '2px solid #000' !important;
    border-radius: '5px';
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.box-style:hover {
    background-color: #02559c !important;
}

.reset-dashboard-btn {
    display: inline-block;
    cursor: pointer;
    font-size: 13px;
    padding: 15px;
    background-color: rgb(7, 69, 128) !important;
    text-transform: uppercase;
    font-weight: 500;
    opacity: 1;
}

.dashboard-split-bar {
    padding: 15px 15px 15px 35px;
    background-color: #07458033;
    color: rgb(0, 50, 101);
}
.settings-menu button {
    width: 100% !important;
}

.settings-menu button:hover, .settings-menu button:active, .settings-menu button:focus {
    background-color: #074680e3 !important;
    color:#fff !important;
}

.settings-menu .dropdown-menu {
    width: 92% !important;
    min-width: 0em !important;
    inset: 0em auto auto 0em !important;
}

.dropdown-item {
    text-align: center !important;
}

.widget-menu {
    background: linear-gradient(180deg, #eff8ff, #d1e7ff 62%);
    /* border:none !important; */
    border-color: #d1e7ff !important;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}
.widget-menu:hover {
    /* background: linear-gradient(135deg, #b3d6ff, #022742); */
    background-image: linear-gradient(180deg, #2b87da 0%, #08447b 62%) !important;
    color:#fff !important;
}
.widget-menu:hover .description, .widget-menu:hover .icon, .widget-menu:hover .drag-handle svg, .widget-menu:hover .widget-header h3 { 
    color: #fff !important;
}
.widget-menu .description {
    font-size: 12px !important;
    color: #022742;
    padding: 0 0 1.3rem;
    font-weight: 400;
}
.widget-menu .icon {
    color: #022742;
    position: absolute;
    top: 1em;
}  
.widget-menu .icon span {
    font-size: 25px !important;

}
.widget-menu .widget-header {
    position: absolute;
    top: 5rem;
}
.widget-menu .widget-header h3 {
    font-size: 1.1rem;
    font-weight: 500;
    color: #022742;
}
.widgets-reporting {
    font-size: small;
}
.navbar .btn {
    border-color: unset;
    background-color: unset;
    padding: .1em .7em;
    border-width: inherit;
} 
.navbar .btn:hover {
    border-color: unset;
    background-color: unset;
    padding: .1em .7em;
} 

@media only screen and (min-width: 1025px) {
.dashboard-navigation .fs-4 {
    font-size: 2.5rem !important;
}
}
@media (max-width: 500px) {
    
}


.widget-table tr{
    padding-left: 10px;
    padding-right: 10px;
}

.widget-table {
    border-collapse: separate;
}